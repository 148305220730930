@import url("https://fonts.googleapis.com/css?family=Outfit");

* {
  font-family: "Outfit";
}

.nav {
  z-index: 1;
  position: fixed;
  width: 300px;
  height: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;
  background-color: #1a1a1a;
  color: white;
}

.nav-item-custom.active {
  background-color: #53b8aa;
  color: white;
}

.progress-bar {
  background-color: #53b8aa;
}

.progress {
  height: 10px;
}

.material-icons,
.material-icons-outlined {
  font-size: 0.9em !important;
}

body {
  margin-left: 300px;
  background-color: white;
}

.banner {
  background-color: #53b8aa;
  color: white;
}

.banner.top {
  padding: 2em;
}

.nav {
  padding: 2em;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.dropdown-item {
  font-size: 0.9em;
}

.dropdown-menu {
  text-align: center;
}

.btn {
  background-color: transparent;
  border-color: #1a1a1a;
  letter-spacing: 1px;
}

.nav .btn {
  color: white;
}

.btn.active,
.btn:hover {
  background-color: #53b8aa;
  color: white;
  border-color: transparent;
}

.logo {
  font-size: large;
  display: block;
  text-align: center;
  font-style: italic;
  margin-bottom: 2rem;
}

.nav-item-custom {
  display: block;
  padding: 0.5rem 2.5rem;
  border-radius: 5px;
  margin-bottom: 0.25rem;
}

.center {
  margin: auto;
  padding: 2.5rem;
  font-style: italic;
  opacity: 50%;
}

.centered-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
  padding: 20px;
}

.text-btn {
  background: none;
  color: #007bff;
  border: none;
  padding: 4px;
  margin-top: 8px;
  cursor: pointer;
  font-size: 14px;
}

.cursor {
  cursor: pointer;
}

.text-btn:hover {
  background-color: transparent;
}

.loading {
  animation: opacity 1s linear infinite;
  opacity: 1;
}

@keyframes opacity {
  from {
    opacity: 100%;
  }

  to {
    opacity: 0%;
  }
}

.location-card {
  border: 1px solid inherit;
  border-radius: 30px;
}

.rect {
  transform-origin: center;
  transform: rotate(90deg);
  transform-box: fill-box;
}

.green-1 {
  fill: #1d5b33;
}

.green-2 {
  fill: #2d6d48;
}

.green-3 {
  fill: #60bf8b;
}

.green-4 {
  fill: #fafefe;
  opacity: 0.5;
}

.red-3 {
  fill: #d85555;
}

.red-2 {
  fill: #892929;
}

.red-1 {
  fill: #9b3434;
}

.red-4 {
  fill: #fafefe;
  opacity: 0.5;
}

.sticky-top {
  background-color: white;
}

.between {
  display: flex;
  justify-content: space-between;
}

.input-group-text {
  background-color: inherit;
  border: 0;
}

.badge {
  font-size: small;
  padding: 1px 10px;
  border-radius: 10px;
  letter-spacing: 1px;
  font-weight: normal;
  background-color: #53b8aa;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  text-transform: uppercase;
}
